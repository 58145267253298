@use "sass:math"; @import "@client/scss/__config.scss"; @import "@theme/scss/_config.scss"; @import "@core/plugins/scss/_mixins.scss";
.vue-select-input-error {
  display: none;
}
.has-error .vs__dropdown-toggle {
  border: 1px solid red !important;
}
.has-error {
  .vue-select-input-error {
    display: block;
  }
}
